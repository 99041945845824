<template>
  <div v-if="product" class="modal-customer-info">
    <sweet-modal ref="modal" overlay-theme="dark" :title="product.nickname" @close="onClose">
      <div class="customer-info-modal-content">
        <div class="customer-info-wrapper">
          <div class="subtitle7 sub3">신청자</div>
          <div class="body4-medium main">{{ product.nickname }}</div>
        </div>
        <div class="customer-memo-wrapper">
          <div class="h8 main">메모</div>
          <div class="text-area-wrapper body4 main">
            <lp-textarea
              v-if="!isMemo"
              :value.sync="memo"
              placeholder="메모를 입력해주세요"
              :max-length="1000"
              :rows="4"
              :cols="50"
            />
            <div v-else>
              <div class="body4 main">{{ memo }}</div>
            </div>
            <button v-if="!isMemo" class="button is-dark body4-medium" @click="saveMemo">저장</button>
            <div v-else class="memo-fixed-del-btn">
              <button class="button is-gray body4-medium" @click="editMemo">수정</button>
              <button class="button is-gray body4-medium" @click="deleteMemo">삭제</button>
            </div>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import LpTextarea from '../component/LpTextArea.vue';
export default {
  name: 'CustomerInfoModal',
  mixins: [],
  components: {
    SweetModal,
    LpTextarea,
  },
  props: {
    product: {
      type: Object,
    },
  },
  created() {},
  data() {
    return {
      memo: '',
      isMemo: false,
      isMemoFixed: false,
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    onClose() {
      this.$emit('close');
    },
    saveMemo() {
      this.$emit('saveMemo', this.memo);
      this.isMemo = true;
      this.$nextTick(() => {
        this.$refs.modal.close();
      });
    },
    editMemo() {
      this.isMemo = false;
      this.isMemoFixed = true;
    },
    deleteMemo() {
      this.$emit('deleteMemo');
      this.memo = '';
      this.isMemo = false;
      this.isMemoFixed = false;
      this.$nextTick(() => {
        this.$refs.modal.close();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.customer-info-modal-content
  display flex
  flex-direction column

.customer-info-wrapper
  display flex
  flex-direction column
  gap 2px
  padding 16px

.customer-memo-wrapper
  display flex
  flex-direction column
  gap 12px
  padding 16px
  border-top 1px solid $gray

.text-area-wrapper
  display flex
  flex-direction column
  gap 8px

.memo-fixed-del-btn
  display grid
  grid-template-columns 1fr 1fr
  align-items center
  gap 8px
</style>

<style lang="stylus">
.modal-customer-info
  .sweet-modal
    width 343px !important

  .sweet-modal .sweet-content
    padding 0px !important

  .sweet-modal .sweet-title
    height 56px !important
    padding 16px !important

  .sweet-modal .sweet-box-actions .sweet-action-close
    width 24px !important
    height 24px !important
    font-size 24px !important
    line-height 24px !important
  .sweet-modal .sweet-title > h2
    font-size 18px !important
    font-weight 700 !important
    line-height 24px !important
    text-align center

@media (max-width: 425px)
  .modal-customer-info
    .sweet-modal
      width 100% !important
      border-radius 0 !important
</style>
